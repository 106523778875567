import styled from "styled-components"

export const StyledLandingPage = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.color.yellow};
  min-height: 100vh;
  position: relative;

  .carousel-root {
    height: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }

  .carousel.carousel-slider .control-arrow {
    padding: 30px;
  }

  .carousel-slider, .slider-wrapper, .slider, .slide {
    height: 100%;
  }

  .carousel .control-next.control-arrow::before {
    border-left: 15px solid #fff;
  }

  .carousel .control-prev.control-arrow::before {
    border-right: 15px solid #fff;
  }

  .carousel .control-arrow::before, .carousel.carousel-slider .control-arrow::before {
    border-top: 30px solid transparent;
    border-bottom: 30px solid transparent;
  }

  .content {
    position: relative;
    z-index: 1;
    padding: 50px;
    pointer-events: none;
  }

  .bien {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: absolute;
    top: calc(50% - 110px);
    right: 10%;

    .content-bien {
      display: flex;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 20px 40px;
      height: 110px;
      justify-content: center;
      flex-direction: column;

      .title {
        color: ${({theme}) => theme.color.yellow};
        font-size: 40px;
        line-height: 40px;
        font-family: ${({theme}) => theme.fontFamily.bigTitle};
        text-shadow: 5px 5px 10px rgba(0, 0, 0, 0.6);
      }

      .subtitle {
        font-family: ${({theme}) => theme.fontFamily.bigTitle};
        color: ${({theme}) => theme.color.black};
        font-size: 20px;
      }
    }

    .logo {
      padding: 20px;
      background-color: ${({theme}) => theme.color.yellow};
      height: 110px;
      display: flex;
      align-items: center;
      justify-content: center;


      img {
        height: 100%;
      }
    }
  }


  .button {
    position: absolute;
    top: 50px;
    right: 50px;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(0, 0, 0);
    width: 100%;
    opacity: 0.37;
    background: radial-gradient(circle farthest-corner at center center, transparent, #000000) no-repeat;
  }

  .footer {
    background-color: #000000;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 80px;
    text-align: center;
    color: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-footer {
      img {
        margin-left: 10px;
        height: 50px;
        width: 50px;

        &:first-child {
          margin-left: 0;
        }
      }

      position: absolute;
      right: 10px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 836px) {
    .bien {
      left: 10%;
      right: 10%;
    }
    .footer {
      .logo-footer {
        display: none;
      }  
    }
  }
`

export const StyledWebsiteCard = styled.div`
  border-top: 14px solid ${({ color }) => color};
  position: relative;
  background-image: url(${({ imageUrl }) => imageUrl});
  background-size: cover;
  min-height: 30vh;

  > a {
    z-index: 3;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
  }

  .overlay {
    position: absolute;
    background-color: ${({ theme }) => theme.color.black};
    transition: 0.5s ease;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    opacity: 0;
    z-index: 1;
  }

  .text {
    font-family: Segoe UI, sans-serif;
    font-weight: 900;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${({ theme }) => theme.color.white};
    font-size: 3em;
    line-height: 48px;
    text-align: center;

    &:after {
      content: "";
      width: 0;
    }
  }

  &:hover {
    .overlay {
      opacity: 0.71;
    }
    .text:after {
      transition: 0.7s;
      position: absolute;
      content: "";
      height: 3px;
      bottom: -2px;
      margin: 0 auto;
      left: 0;
      right: 0;
      width: 80%;
      background: ${({ color }) => color};
    }
  }

  @media screen and (max-width: 1000px) {
    position: relative;
    margin-top: -1px;
  }
`

export const StyledLandingCard = styled.div`
  background-image: url(${({imageUrl}) => imageUrl});
  background-size: cover;
  height: 100%;
  
  .bien{
    .content-bien{
      .title {
        color: ${({color}) => color};
        font-family: Segoe UI Black, sans-serif;
      }
    }
    .logo {
      background-color: ${({color}) => color};
    }
  }
  .overlay{
    a {
      display: block;
      height: 100%;
      width: 100%;
      text-decoration: none;
    }  
  }  
`

export const StyledBigTitle = styled.div`
  font-family: ${({theme}) => theme.fontFamily.bigTitle};
  font-weight: 700;
  color: ${({theme}) => theme.color.white};

  .backgroundImage {
    position: absolute;
    left: -150px;
    top: -150px;
  }

  .zone {
    font-size: 56px;
    line-height: 60px;
    margin-bottom: 20px;
  }

  .maeva {
    font-size: 190px;
    line-height: 120px;
    text-shadow: 10px 10px 30px rgba(0, 0, 0, 0.6);
  }

  .welcome {
    padding-top: 20px;
    font-size: 75px;
    line-height: 70px;
    text-shadow: 10px 10px 30px rgba(0, 0, 0, 0.6);
  }

  @media screen and (max-width: 836px) {
    .zone {
      margin-top: 50px;
      font-size: 36px;
      line-height: 30px;
    }

    .maeva {
      font-size: 110px;
      line-height: 80px;
    }

    .welcome {
      font-size: 45px;
      line-height: 50px;
    }
  }
`
