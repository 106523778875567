import React from "react"
// import PropTypes from 'prop-types';
import { StyledWebsiteCard } from "./styles"

const WebsiteCard = ({ title, color, image, urlPage }) => (
  <StyledWebsiteCard color={color} imageUrl={image}>
    <a href={urlPage} />
    <div className="text">
      {title}
    </div>
    <div className="overlay" />
  </StyledWebsiteCard>
)

WebsiteCard.propTypes = {}

export default WebsiteCard
