import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import WebsiteCard from "components/LandingPage/WebsiteCard"
import { Row, Col } from "antd"

const ViewsListAllWebsite = ({footerDisplay}) => {
  const allTaxonomyTermSiteInternet = useStaticQuery(
    graphql`
      query {
        drupal {
          taxonomyTermQuery(
            filter: {
              conditions: {
                operator: EQUAL
                value: ["site_internet"]
                field: "vid"
              }
            }
          ) {
            entities {
              ...TaxonomyTermSiteInternet
            }
          }
        }
      }
    `,
  )

  const all_website_info = allTaxonomyTermSiteInternet.drupal.taxonomyTermQuery.entities.map(
    (term, index) => {
      const website_info = {
        title: term.name,
        color: term.fieldCouleur.color,
        image: term.fieldMediaImage.entity.fieldMediaImage.siteInternet.url,
        url_page: term.fieldLien.url.path,
        key: term.entityId,
      }

      return (
        <Col key={index} lg={footerDisplay ? 12 : 12} md={12} xs={24}>
          <WebsiteCard
            key={website_info.key}
            title={website_info.title}
            color={website_info.color}
            image={website_info.image}
            urlPage={website_info.url_page}
          />
        </Col>
      )
    },
  )

  return <Row type="flex">{all_website_info}</Row>
}

export default ViewsListAllWebsite
